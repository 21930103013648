#security
{
    // margin-bottom: 10rem;

    .security_wrapper
    {
        *
        {
            @include themify()
            {
                color: themed('secondary');
                transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;
            }
        }
        .key_toggle
        {
            @include themify()
            {
                color: themed('secondary');
            }
        }
        .label
        {
            user-select: none;
            width: 100%; 
            text-align: center; 
                                    
            display: inline-flex;
            @include themify()
            {
                background-color: themed('grey-000');
            }
            gap: 0.5rem;
            align-items: center;
            justify-content: left;
            .icon
            {
                transition: color $trans-effect $trans-regular;
                width: $medium-size;
                height: $medium-size;
            }

            cursor: pointer;
            
            &::after
            {
                content: "";
                margin-left: 0.5rem;
                flex: 1;
                transition: background-color $trans-effect $trans-regular;
                @include themify()
                {
                    background-color: themed('secondary');
                }
                height: 1px;
                width: 100%;
            }
        }
        
        .public_key
        {
            margin: 0rem;
            height: fit-content;
            max-height: 0rem;
            overflow: hidden;
            display: block;
            transition: max-height 1s $trans-effect, background-color $trans-effect $trans-regular;
            border-radius: $radius-regular;
            @include themify()
            {
                background-color: themed('grey-100');
            }
            
            &.shown
            {
                overflow-x: auto;
                max-height: 150rem;
            }
            .pgp_key
            {
                width: fit-content;
                margin: 0rem;
                padding: 2rem;
                white-space: pre-wrap;
            }
        }
    }
}