.input_wrapper
{
    width: 100%;
    height: auto;
    display: inline-flex;
    flex-direction: column;
    margin: 0rem;
    padding: 0rem;

    grid-column: span 2;

    &.half
    {
        grid-column: span 1;
    }
    
    position: relative;

}

.text_label
{
    margin: 0rem;
    opacity: 0.0;
    transition: $trans-regular;
    
    &.shown
    {
        transition: $trans-regular;
        opacity: 1.0;

        @include themify()
        {
            color: themed("grey-800");
        }
    }
    &.error
    {
        @include themify()
        {
            color: themed("error");
        }
    }
}

textarea
{
    resize: none;
}

.select-wrapper
{
    position: relative;
    &::after
    {
        position: absolute;
        font-size: 1.4rem;
        top: 0;
        display: flex;
        align-items: center;
        right: 1rem;
        height: 100%;
        content: "⯆";
        // float: right;
        @include themify()
        {
            color: themed("grey-800");
            transition: color $trans-effect $trans-regular;
        }
    }
}

textarea, input[type=text], input[type=password], input[type=date], select
{

    width: 100%;
    padding: 1rem;
    margin-top: 0.3rem;

    outline: none;
    border-radius: $radius-regular;
    border: 0.1rem solid;
    
    
    transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular, border-color $trans-effect $trans-regular;
    
    @include themify()
    {
        color: themed("grey-800");

        background-color: themed("grey-150");
        border-color: themed("grey-150");

        &::placeholder
        {
            color: rgba($color: themed("grey-800"), $alpha: 0.35) ;
        }
        
        &:hover
        { 
            background-color: mix(themed("grey-150"), themed("primary"), $color-mix-most);
            border-color: themed("grey-150");
        }
        &.error
        {
            
            border-color: themed("error");
        }
        &:focus, &:active
        {
            
            border-color: themed("primary");
        }
        &:disabled, &:disabled:focus, &:disabled:active
        {
            color: themed("grey-500");
            border-color: themed("grey-300");
            background-color: themed("grey-100");
        }
        
        &.elevated
        {
            background-color: themed("grey-200");
            border-color: themed("grey-200");
            &:hover
            {
                
                background-color: mix(themed("grey-200"), themed("primary"), $color-mix-most);
                border-color: themed("grey-200");
            }
            &.error
            {
                
                border-color: themed("error");
            }
            &:focus, &:active
            {
                
                border-color: themed("primary");
            }
            &:disabled, &:disabled:focus, &:disabled:active
            {
                color: themed("grey-500");
                border-color: themed("grey-300");
                background-color: themed("grey-150");
            }

        }
    }
}

select
{
    appearance: none;
    cursor: pointer;
}

.checkbox_wrapper
{
    display: inline-flex;
    flex-direction: row;
    height: 100%;
    min-width: fit-content;
    width: fit-content;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}

.checkbox_label
{
    min-width: fit-content;
    transition: color $trans-effect $trans-regular;
    @include themify()
    {
        color: themed("grey-750");
    }

    &.disabled
    {
        @include themify()
        {
            color: themed("grey-500");
        }

    }
    
    &.checked
    {
        @include themify()
        {
            color: themed("grey-900");
        }
    }
}

input[type=checkbox]
{
    cursor: pointer;
    appearance: none;
    margin: 0rem;
    width: 1.75rem;
    height: 1.75rem;
    border: 1px solid;
    transition: background-color $trans-effect $trans-regular, border-color $trans-effect $trans-regular;
    // margin-right: 1.5rem;
    border-radius: 0.5rem;


    @include themify()
    {
        color: themed("primary");
        background-color: themed("grey-200");
        border-color: themed("grey-400");
        &:hover
        {
            background-color: mix(themed("grey-200"), themed("primary"), $color-mix-regular);
            border-color: mix(themed("grey-400"), themed("primary"), $color-mix-regular);
        }
        &:checked, &:checked:active
        {
            color: themed("primary");
            background-color: themed("primary");
            border-color: themed("primary");
            
            &::after
            {
                color: themed("grey-000");
            }
        }
        &:disabled
        {
            border-color: themed("grey-300");
            background-color: themed("grey-150");
        }
        &:read-only
        {
            border-color: themed("grey-300");
        }
    }
    
    display: flex;
    justify-content: center;
    align-items: center;

    &::after
    {
        content: "\2714";
        transition: color $trans-effect $trans-regular;
        color: transparent;
    }
    
    &:checked, &:checked:active
    {
        &::after
        {
            content: "\2714";
        }
    }

    
    &:disabled
    {
        cursor: default;
        border: 1px solid;
    }
}