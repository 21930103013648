/*
 * File: _button.scss
 * Author: Yusuf Saquib
 */

a > .btn
{
    &:focus-visible
    {
        outline: none;
    }
}

.btn
{
    border: none;
    border-radius: $radius-small;
    text-decoration: none;
    // flex: 1 1 auto;
    
    transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular, box-shadow $trans-effect $trans-regular;
    position: relative;
    padding: 1rem 1.3rem;
    min-width: 11rem;
    min-height: 3.8rem;
    
    @include media("<=tablet")
    {
        padding: 0.9rem 1.1rem;
    }
    @include media("<=phone")
    {
        padding: 0.75rem 0.9rem;
    }


    @include themify()
    {
        color: themed('grey-000');
        background-color: themed('primary');
        &:hover
        {
            background-color: mix(themed('primary-l'), themed('grey-900'), $color-mix-regular);
        }
        &:focus-visible
        {
            outline: themed('secondary') dashed 2px;
        }
        &:active:focus
        {
            transition: none;
            background-color: themed('primary-d');
            // background-color: mix(themed('primary-l'), themed('grey-300'), $color-mix-least);
        }
        &:disabled
        {
            background-color: mix(themed('grey-000'), themed('primary-d'), 60%);
        }
        &.secondary
        {
            background-color: themed('grey-750');
            &:hover
            {
                background-color: themed('grey-900');
            }
            &:focus-visible
            {
                outline: themed('secondary') dashed 2px;
            }
            &:active:focus
            {
                transition: none;
                background-color: themed('grey-700');
            }
            &:disabled
            {
                background-color: themed('grey-500');
            }
        }
    }
    

    
    &:hover
    {
        cursor: pointer;
    }
    &:disabled
    {
        cursor: default;
    }
    
    &.no_background
    {
        background: none;
        padding-right: 0rem;
        padding-left: 0rem;
        // width: fit-content;

        @include themify()
        {
            transition: color $trans-effect $trans-regular;
            color: themed('grey-900');
            &:hover
            {
                color: themed('primary');
            }
            &:focus-visible
            {
                color: themed('primary-l');
            }
            &:active:focus
            {
                color: themed('primary-d');
                // color: mix(themed('primary-l'), themed('grey-300'), $color-mix-least);
            }
            &:disabled
            {
                color: themed('grey-500');
            }
        }
        
        &:hover, &:active:focus, &:focus, &:disabled
        {
            background: none;
            box-shadow: none;
        }
    }

    @include themify()
    {
        &.confirm
        {
            transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;
            background-color: themed('success');
            color: themed('grey-000');
            &:hover
            {
                background-color: mix(themed('success'), themed('grey-900'), 85%);
            }
            &:focus-visible
            {
                outline: themed('secondary') dashed 2px;
            }
            &:active:focus
            {
                background-color: mix(themed('success'), themed('grey-900'), 70%);
            }
            &:disabled
            {
                background-color:  mix(themed('success'), themed('grey-000'), 30%);
            }
        }
        &.reject
        {
            transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;
            background-color: themed('error');
            color: themed('grey-000');
            &:hover
            {
                background-color: mix(themed('error'), themed('grey-900'), 85%);
            }
            &:focus-visible
            {
                outline: themed('secondary') dashed 2px;
            }
            &:active:focus
            {
                background-color: mix(themed('error'), themed('grey-900'), 70%);
            }
            &:disabled
            {
                background-color:  mix(themed('error'), themed('grey-000'), 30%);
            }
        }
        &.warning
        {
            transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;
            background-color: themed('warning');
            color: themed('grey-000');
            &:hover
            {
                background-color: mix(themed('warning'), themed('grey-900'), 85%);
            }
            &:focus-visible
            {
                outline: themed('secondary') dashed 2px;
            }
            &:active:focus
            {
                background-color: mix(themed('warning'), themed('grey-900'), 70%);
            }
            &:disabled
            {
                background-color:  mix(themed('warning'), themed('grey-000'), 30%);
            }
        }
        &.close
        {
            transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;
            background-color: themed('grey-300');
            color: themed('grey-800');
            &:hover
            {
                background-color: mix(themed('grey-300'), themed('grey-900'), 85%);
                color: themed('secondary');
            }
            &:focus-visible
            {
                outline: themed('secondary') dashed 2px;
            }
            &:active:focus
            {
                background-color: mix(themed('grey-300'), themed('grey-900'), 70%);
                color: themed('secondary');
            }
            &:disabled
            {
                background-color:  mix(themed('grey-300'), themed('grey-000'), 30%);
            }
        }
        &.neutral
        {
            transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;
            background-color: themed('grey-800');
            color: themed('grey-100');
            &:hover
            {
                background-color: mix(themed('grey-800'), themed('grey-100'), 85%);
            }
            &:focus-visible
            {
                outline: themed('primary') dashed 2px;
            }
            &:active:focus
            {
                background-color: mix(themed('grey-800'), themed('grey-100'), 70%);
            }
            &:disabled
            {
                background-color:  mix(themed('grey-800'), themed('grey-100'), 30%);
            }
        }
    }
}

