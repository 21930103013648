/*
 * File: _typography.scss
 * Author: Yusuf Saquib
 */

@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Naskh+Arabic:wght@400;500;600;700&display=swap&subset=arabic');

h1 
{
    @include themify()
    {
        color: themed('grey-900');
    }
    font: $medium-weight $large-size $serif-font;
}

h2
{
    font: $medium-weight $regular-size $mono-font;
    @include themify()
    {
        color: themed('primary');
    }
}

title
{
    font: $bold-weight $regular-size $sans-font;
    @include themify()
    {
        color: themed('primary');
    }
}

*, *::before, *::after
{
    -webkit-font-feature-settings: "lnum";
    -moz-font-feature-settings: "lnum";
    font-feature-settings: "lnum";
}



// $ Section ==================================================================

.sec_title
{
    font: $bold-weight $large-size $serif-font;
}
.sec_subtitle
{
    font: $regular-weight $medium-size $sans-font;
}

// $ PGP Key ==================================================================

.key_toggle
{
    font: $medium-weight $regular-size $mono-font;
}
.pgp_key
{
    font: $regular-weight $little-size $mono-font;
}

// $ Button ===================================================================

.btn
{
    font: $medium-weight $small-size $sans-font;
    &.no_background
    {
        font-weight: $regular-weight;
    }
}

// $ Dialog Box ===============================================================

.dialog_box
{
    .dialog_box_title
    {
        font: $regular-weight $Medium-size $serif-font;
    }
    .dialog_box_message
    {
        font: $medium-weight $regular-size $sans-font;
        &.error, &.warning, &.success
        {
            font: $semi-weight $regular-size $sans-font;
        }
    }

}

// $ Hero Banner ==============================================================

#banner
{
    .pre
    {
        font: $medium-weight $regular-size $mono-font;
    }
    .my_name
    {
        font: $bolder-weight $Largest-size $sans-font;
    }
    .my_title
    {
        font: $bold-weight $Large-size $sans-font;
    }
}

// $ Contact ==================================================================

#form_error
{
    font: $medium-weight $small-size $sans-font;
}
.google_recaptcha_branding
{
    font: $regular-weight $Tiny-size $sans-font;
}

// $ Projects =================================================================

.project_card_wrapper
{
    .project_tag
    {
        font: $semi-weight $little-size $mono-font;
    }
    .project_title
    {
        font: $bold-weight $Medium-size $sans-font;
        @include media(">tablet-big")
        {
            &.featured_project_text { font-size: $large-size; }
            &.large_project_text { font-size: $large-size; }
        }
        @include media(">tablet", "<=tablet-big")
        {
            &.featured_project_text { font-size: $large-size; }
        }
        // @include media(">=desktop-big")
        // {
        //     &.featured_project_text { font-size: $medium-size; }
        //     &.large_project_text { font-size: $medium-size; }
        // }
    }

    .project_description
    {
        font: $regular-weight $regular-size $sans-font;
        line-height: $line-height-medium;
        @include media(">tablet-big")
        {
            &.featured_project_text { font-size: $regular-size; }
            &.featured_large_text { font-size: $regular-size; }
            
        }
        @include media(">tablet", "<=tablet-big")
        {
            &.featured_project_text { font-size: $regular-size; }
        }
    }
    
    .project_languages
    {
        font: $regular-weight $small-size $mono-font;
        @include media(">tablet-big")
        {
            &.featured_project_text { font-size: $Small-size; }
            &.large_project_text { font-size: $Small-size; }
            
        }
        @include media(">tablet", "<=tablet-big")
        {
            &.featured_project_text { font-size: $Small-size; }
        }
    }
    
    .wip_label
    {
        font: $regular-weight $Little-size $mono-font;
    }
    
    .links_list
    {
        font: $semi-weight $Little-size $mono-font;
    }
    
    &.error
    {
        font: $medium-weight $Regular-size $sans-font;
    }
}

// $ Career ====================================================================

.career_item_title
{
    font: $medium-weight $regular-size $sans-font;
}
.career_item_organization
{
    font: $medium-weight $regular-size $sans-font;
}
.career_item_subtitle
{
    font: $medium-weight $small-size $mono-font;
}
.career_item_location
{
    font: $light-weight $Little-size $mono-font;
}
.career_item_date
{
    font: $light-weight $Little-size $mono-font;
}
.career_item_desc
{
    li
    {
        font: $regular-weight $small-size $sans-font;
    }
}
.career_item_error
{
    font: $regular-weight $regular-size $sans-font;
}

// $ Blog (not including markdown) ============================================

#blog
{
    #blog_button_back
    {
        font: $medium-weight $Small-size $sans-font;
    }
    #blog_edit_button
    {
        font: $medium-weight $Small-size $mono-font;
    }
    #blog_title
    {
        font: $bold-weight $large-size $serif-font;
    }
    .info_item
    {
        font: $regular-weight $small-size $mono-font;
    }
    .blog_tag
    {
        font: $semi-weight $little-size $mono-font;
    }
}

// $ Editing Blog =============================================================

#edit_blog .blog_title_field input
{
    font: $medium-weight $large-size $serif-font;
}

// $ Blog List ================================================================

#blogslist
{
    .blogs_list_options
    {
        font: $medium-weight $small-size $sans-font;
    }
    .blogs_per_page li button
    {
        font: $medium-weight $small-size $sans-font;
        &.selected
        {
            font: $semi-weight $small-size $sans-font;
        }
    }
    .blogs_list_item.create_new
    {
        font: $medium-weight $Small-size $mono-font;
    }
    .blogs_list_tag
    {
        font: $semi-weight $Tiny-size $mono-font;

    }
    .blogs_list_title
    {
        font: $regular-weight $medium-size $sans-font;
    }
    .private_blog .blogs_list_title::after 
    {
        font: $medium-weight $regular-size $mono-font;
    }
    .error
    {
        font: $medium-weight $medium-size $sans-font;
    }
}

// $ Inbox ====================================================================

#inbox
{
    .msgli
    {
        &.sender
        {
            font: $bold-weight $regular-size $sans-font;
        }
        &.time
        {
            font: $regular-weight $little-size $mono-font;
        }
        &.subject
        {
            font: $semi-weight $Little-size $sans-font;
        }
    }

    #message
    {
        font: $regular-weight $small-size $sans-font;
        .msginfo
        {
            &.sender
            {
                font-weight: $bold-weight;
            }
            .sender_email
            {
                font-weight: $light-weight;
            }
            &.subject
            {
                font: $regular-weight $large-size $sans-font;
            }
            &.date
            {
                font: $regular-weight $little-size $sans-font;
            }
        }
        .msgbtn
        {
            font: $medium-weight $Little-size $sans-font;
        }
    }
    .empty_list, .empty_message
    {
        font: $medium-weight $regular-size $sans-font;
    }
}

// $ Pagination ===============================================================

.pagination
{
    .pagination_icon,
    .pagination_button
    {
        font: $medium-weight $small-size $sans-font;
    }
}

// $ Inputs ===================================================================
.input_wrapper
{
    font: $regular-weight $regular-size $sans-font;
}

.text_label
{
    font: $regular-weight $Little-size $sans-font;
}

.editor_wrapper .editor_area
{
    font: $regular-weight $Small-size $mono-font;
}

textarea, input[type=text], input[type=password], input[type=date], select
{
    font: $regular-weight $Small-size $sans-font;
}

input[type=checkbox]
{
    &::after
    {
        font-size: $regular-size;
    }
}

.checkbox_label
{
    font: $regular-weight $small-size $sans-font;
}

// $ Footer ===================================================================

#footer .footer_label
{
    font: $regular-weight $Little-size $mono-font;
}

// $ Header ===================================================================

#header
{
    .header_title
    {
        font: $bold-weight $medium-size $sans-font;
    }
    .header_item
    {
        font: $medium-weight $Little-size $mono-font;
    }
}

// $ Error 404 Page ===========================================================

#pagenotfound
{
    .err_title
    {
        font: $black-weight $Largest-size $sans-font;
    }
    .err_subtitle
    {
        font: $medium-weight $Medium-size $mono-font;
    }
    .err_home
    {
        font: $regular-weight $Regular-size $sans-font;
    }
}

// $ Loader ===================================================================

#loader .loading_text
{
    font: $light-weight $large-size $sans-font;
}

// $ Admin Dashboard ==========================================================

.admin
{
    .category_title
    {
        font: $semi-weight $medium-size $sans-font;
    }
    .message
    {
        font: $regular-weight $regular-size $sans-font;
    }
}

#admin_banner
{
    .banner_wrapper
    {
        .banner_buttons
        {
            :nth-child(n)
            {
                font-size: $little-size;
            }
        }    
        .pre { font-size: $small-size; }
        
        .my_name{ font-size: $large-size; }
        
        .my_title { font-size: $Medium-size; } 
    }
}

.admin_list
{
    .item_title
    {
        font: $regular-weight $Small-size $sans-font;
    }
}

// $ Login & Register =========================================================

#login, #register
{
    .banner
    {
        font: $semi-weight $small-size $sans-font;
        line-height: 1.5;
    }
}