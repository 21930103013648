/*
 * File: acwrapper.scss
 * Author: Yusuf Saquib
 */

#ac_wrapper
{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
    @include media("<=tablet")
    {
        grid-template-columns: repeat(1, 1fr);
        gap: 5vh;
    }
}