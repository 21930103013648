#blogslist
{
    .blogs_list_wrapper
    {
        transition: color $trans-effect $trans-regular;
        .blogs_list_options
        {
            display: inline-flex;
            flex-direction: row-reverse;
            align-items: center;
            width: 100%;
            
            .option
            {
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                .blogs_per_page
                {
                    list-style-type: none;
                    padding: 0rem;
                    margin: 0rem;
                    
                    li 
                    {
                        margin-left: 1.5rem;
                        display: inline;
                    }
                    
                    li button 
                    {
                        transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;
                        width: $large-size;
                        height: $large-size;
                        border-radius: 5rem;
                        border: none;
                        &:hover 
                        {
                            cursor: pointer;
                        }
                    }
                }
            }
            
            // justify-content: flex-start;
        }

        .blogs_list
        {
            display: grid;
            margin: 2rem 0rem;
            gap: 1rem;

            padding: 0rem;

            .blogs_list_item_wrapper
            {
                display: inline-flex;
                justify-content: flex-start;
                align-items: center;
                flex-direction: row;
                
                gap: 1rem;

                @include media("<=tablet")
                {
                    flex-direction: column;
                    margin-bottom: 0.5rem;
                }

            }
            .blogs_list_buttons
            {
                flex: 2 3;
                display: grid;
                gap: 1rem;
                grid-template-columns: 1fr 1fr;
                place-items: stretch;
                height: 100%;
                width: 100%;
                
                .blogs_list_item_button
                {
                    border-radius: $radius-regular;
                    padding: 0.8rem;
                    display: grid;
                    place-items: center;
                    box-shadow: $blog-list-box-shadow;
    
                    &:hover
                    {
                        cursor: pointer;
                    }
                }
            
            }


            .blogs_list_item
            {
                box-shadow: $blog-list-box-shadow;
                width: 100%;
                flex: 10 6;

                text-decoration: none;

                
                display: inline-flex;
                gap: 2rem;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                list-style-type: none;
                padding: 2rem;
                @include media(">desktop-big")
                {
                    padding: 4rem;   
                }
                border-radius: $radius-regular;
                
                @include media("<=tablet")
                {
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 1rem;
                    gap: 1rem;
                }

                
                &.create_new
                {
                    padding: 1rem;
                    align-items: center;
                    justify-content: center;
                }
                
                &:hover
                {
                    cursor: pointer;
                }
                
                &.loading
                {
                    height: 6.4rem;
                    box-shadow: none;
                    cursor: default;
                }

                &.error
                {
                    justify-content: center;
                    align-items: center;
                }

                .blogs_list_tags
                {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 1rem;
                    flex-wrap: wrap;
                }

                .blogs_list_tag
                {
                    text-align: center;
                    transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;
                    padding: 0.4rem 0.6rem;
                    border-radius: $radius-regular;
                    
                    margin: 0rem;
                    flex: 0 0 auto;
                }

                .blogs_list_title
                {
                    flex: 1 1 auto;
                    margin: 0rem;
                }
            }
        }
    }
    
    @include themify()
    {
        .blogs_list_wrapper
        {
            color: themed("grey-850");
            
            .blogs_list_options
            {
                .option .blogs_per_page
                {
                    li button
                    {
                        color: themed("grey-900");
                        background-color: themed("grey-150");
                        &:hover
                        {
                            color: themed("primary");
                            background-color: themed("grey-200");
                        }
                        &:active:focus, &:active
                        {
                            transition: none;
                            background-color: themed("grey-300");
                        }
                        
                        &.selected
                        {
                            color: themed("grey-000");
                            background-color: themed("primary");

                            &:hover, &:active:focus, &:active
                            {
                                transition: none;
                                background-color: themed("primary");
                            }
                        }
                    }
                }

            }

            .blogs_list
            {
                .blogs_list_item_button
                {
                    color: themed("grey-700");
                    background-color: themed("grey-150");
                    transition: background-color $trans-regular $trans-effect, color $trans-regular $trans-effect;
                    
                    * { transition: none; }

                    &:hover
                    {    
                        background-color: themed("grey-200");
                        color: themed("secondary");
                    }

                    &:active
                    {    
                        background-color: themed("grey-300");
                        transition: none;
                    }
                    
                    &.delete
                    {    
                        &:hover 
                        {
                            color: adjust-color(themed("error"), $saturation: 90%);
                        }
                    }

                    &.private_blog
                    {
                        background-color: mix(themed("grey-100"), themed("error"), $color-mix-least);
                        color: themed("error");
                        &:hover 
                        {
                            background-color: mix(themed("grey-150"), themed("error"), $color-mix-least);
                            color: adjust-color(themed("error"), $saturation: 90%);
                        }
                        &:active 
                        {
                            background-color: mix(themed("grey-200"), themed("error"), $color-mix-least);
                            transition: none;
                        }
                    }

                    &.unlisted_blog
                    {
                        background-color: mix(themed("grey-100"), themed("warning"), $color-mix-least);
                        color: themed("warning");
                        &:hover 
                        {
                            background-color: mix(themed("grey-150"), themed("warning"), $color-mix-least);
                            color: adjust-color(themed("warning"), $saturation: 90%);
                        }
                        &:active 
                        {
                            background-color: mix(themed("grey-200"), themed("warning"), $color-mix-least);
                            transition: none;
                        }
                    }
                }
                .blogs_list_item:not(.loading)
                {
                    .blogs_list_title
                    {
                        transition: color $trans-regular $trans-effect;
                    }

                    .blogs_list_tag
                    {
                        color: themed("grey-000");
                        background-color: themed("primary");
                    }
                    
                    background-color: themed("grey-150");
                    transition: background $trans-regular $trans-effect;
                    
                    &:hover
                    {    
                        background-color: themed("grey-200");
                        .blogs_list_title
                        {
                            color: themed("primary");
                        }
                        .blogs_list_tag
                        {
                            color: themed("grey-150");
                        }
                    }
                    &:active
                    {
                        transition: none;
                        background-color: themed("grey-300");
                    }
                    
                    &.featured
                    {
                        .blogs_list_tag
                        {
                            background-color: themed("secondary");
                        }
                        &:hover
                        {    
                            background-color: themed("grey-200");
                            .blogs_list_title
                            {
                                color: themed("secondary");
                            }
                        }
                        &:active
                        {
                            transition: none;
                            background-color: themed("grey-300");
                        }
                    }

                    &.wip
                    {
                        .blogs_list_tag
                        {
                            background-color: themed("grey-700");
                        }
                        &:hover
                        {    
                            background-color: themed("grey-200");
                            .blogs_list_title
                            {
                                color: themed("grey-700");
                            }
                        }
                        &:active
                        {
                            transition: none;
                            background-color: themed("grey-300");
                        }
                    }

                    &.private_blog
                    {
                        background-color: mix(themed("grey-100"), themed("error"), $color-mix-least);
                        .blogs_list_tag
                        {
                            background-color: themed("error");
                        }
                        .blogs_list_title
                        {
                            color: themed("error");
                        }
                        &:hover
                        {    
                            background-color: mix(themed("grey-150"), themed("error"), $color-mix-least);
                            .blogs_list_title
                            {
                                color: adjust-color(themed("error"), $saturation: 90%);
                            }
                            .blogs_list_tag
                            {
                                background-color: adjust-color(themed("error"), $saturation: 90%);
                            }
                        }
                        &:active
                        {
                            transition: none;
                            background-color: mix(themed("grey-200"), themed("error"), $color-mix-least);
                        }
                    }

                    &.unlisted_blog
                    {
                        background-color: mix(themed("grey-100"), themed("warning"), $color-mix-least);
                        .blogs_list_tag
                        {
                            background-color: themed("warning");
                        }
                        .blogs_list_title
                        {
                            color: themed("warning");
                        }
                        &:hover
                        {    
                            background-color: mix(themed("grey-150"), themed("warning"), $color-mix-least);
                            .blogs_list_title
                            {
                                color: adjust-color(themed("warning"), $saturation: 90%);
                            }
                            .blogs_list_tag
                            {
                                background-color: adjust-color(themed("warning"), $saturation: 90%);
                            }
                        }
                        &:active
                        {
                            transition: none;
                            background-color: mix(themed("grey-200"), themed("warning"), $color-mix-least);
                        }
                    }
                    
                    &.create_new
                    {
                        color: themed("grey-000") !important;
                        background-color: mix(themed("grey-150"), themed("success"), 50%);
                        transition: background $trans-regular $trans-effect, color $trans-regular $trans-effect;
                        &:hover
                        {
                            background-color: mix(themed("success"), themed("grey-150"), $color-mix-less);
                        }
                        &:active
                        {
                            transition: none;
                            background-color: mix(themed("success"), themed("grey-150"), $color-mix-least);
                        }
                    }

                    &.error
                    {
                        transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;
                        cursor: default;
                        color: themed("grey-000");
                        background-color: themed("error");
                    }
                }
            }
        }
    }
}