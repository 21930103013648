.pagination
{
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 5rem;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    
    
    li
    {
        margin: 0rem .8rem;
    }

    .pagination_icon,
    .pagination_button
    {
        transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;
        user-select: none;
        border: none;

        
        display: grid;
        place-content: center;
        
        width: $large-size;
        height: $large-size;
        border-radius: 5rem;

        &:hover
        {
            cursor: pointer;
            font-weight: $semi-weight;
        }
        
        &.selected
        {
            font-weight: $semi-weight;
        }

        &.with_icon
        {
            * {transition: none;}
        }
    }

    .pagination_icon, .pagination_icon:hover
    {
        cursor: default;
    }

    @include themify()
    {
        .pagination_icon
        {
            color: themed('grey-900');    
        }
        
        .pagination_button
        {
            color: themed('grey-900');
            background-color: themed('grey-150');    
            
            &.with_icon
            {
                background-color: themed('grey-000');
            }
            
            &:hover
            {
                color: themed('primary');
                background-color: themed('grey-200');    
            }
            &:active
            {
                transition: none;
                background-color: themed('grey-300');    
            }
            
            &.selected
            {
                color: themed('grey-150');
                background-color: themed('primary');
                &:active, &:active:focus, &:hover
                {
                    transition: none;
                    background-color: themed('primary');
                }
            }
            
            &:disabled
            {
                color: themed('grey-500');
                background-color: themed('grey-000');
                cursor: default;
            }

        }

    }
}