/*
 * File: _projectcard.scss
 * Author: Yusuf Saquib
 */

.project_card_wrapper
{
    @include themify()
    {
        background-color: themed("grey-150");

        .project_links .links_list .links_item
        {

            color: themed("primary");
            &:hover
            {
                color: themed("primary-l");
            }
            &:focus:active, &:active
            {
                transition: none;
                color: themed("primary-d")
            }
        }

        .date_tag
        {
            background-color: themed("primary");
            color: themed("grey-000");
        }

        
        &.featured_project .project_links .links_list .links_item
        {
            color: themed("secondary");
            &:hover
            {
                color: themed("secondary-l");
            }
            &:focus:active, &:active
            {
                transition: none;
                color: themed("secondary-d")
            }
        }
        .featured_tag, &.featured_project .date_tag
        {
            background-color: themed("secondary");
            color: themed("grey-000");
        }

        &.wip_project .project_links .links_list .links_item
        {
            
            color: themed("grey-700");
            &:hover
            {
                color: themed("grey-900");
            }
            &:focus:active, &:active
            {
                color: themed("grey-500");
            }
        }
        .wip_tag, &.wip_project .date_tag
        {
            background-color: themed("grey-700");
            color: themed("grey-000");
        }
        .wip_bar_wrapper
        {
            color: themed("grey-700");
            .wip_bar
            {
                background-color: themed("grey-300");
                .wip_progress
                {
                    background-color: currentColor;
                }
            }
        }
        .project_description
        {
            color: themed("grey-800");
        }
        .project_languages
        {
            color: themed("grey-600");
        }
        
        &.error
        {
            color: themed("grey-000");
            background-color: themed("error");
        }
    }
    display: grid;
    place-items: stretch;
    transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;
    min-height: $project-card-height;
    
    @include media(">tablet", "<=tablet-big")
    {
        grid-column: span 3;
    }
    @include media("<=tablet")
    {
        grid-column: span 6;
    }

    grid-column: span 2;
    border-radius: $radius-large;
    box-shadow: $project-card-box-shadow;
    @include media(">desktop-big")
    {
        grid-column: span 2;
    }
    
    
    .project_card
    {
        padding: $padding-card-regular;
        @include media("<=tablet")
        {
            padding: $padding-card-less;
        }
        display: flex;
        flex-direction: column;
        border: none;
        margin: 0rem;
    }
    
    .project_links
    {

        margin: 1em 0rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 1rem;
        user-select: none;
        transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;

        
        .links_list
        {
            margin: 0rem;
            padding: 0rem;
            list-style-type: none;
            
            .links_item
            {
                transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;
                cursor: pointer;
                display: inline;
                margin: 0rem;
                margin-right: 1rem;
            }
        }

    }


    .project_tag
    {
        transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;

        align-self: left;
        width: fit-content;
        margin: 0rem;
        padding: 0.2rem 0.6rem;
        border-radius: $radius-small;
        display: grid;
        place-items: center;
    }

    
    .project_title
    {
        transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;
        margin: 0rem;
    }
    
    .project_description
    {
        transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;
        width: 100%;
        text-align: start;
        white-space: pre-wrap;
        flex: 1 1 auto;
        // flex: 1 1;
        margin: 0rem 0rem 3rem;
    }
    
    .project_languages, .wip_bar_wrapper
    {
        overflow: hidden;
        margin: 0rem;
        padding: 0rem;
        width: 100%;
    }
    
    .project_languages
    {
        min-height: fit-content;
        flex: 0 0 auto;
        list-style-type: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        
        justify-content: flex-start;
        align-items: center;
        
        li
        {
            display: inline;
            text-align: left;
            
            transition: color $trans-effect $trans-regular;
            
            &::after
            {
                padding: 0rem 0.75rem;
                content: "•";
            }
        }
        
        :last-child
        {
            &::after
            {
                padding-left: 0.75rem;
                content: "";
            }
        }
    }
    .wip_bar_wrapper
    {
        min-height: 0;
        transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;
        flex: 0 0 auto;
        
        .wip_bar
        {
            margin-top: 0.5rem;
            border-radius: $radius-small;
            // padding: 1rem;
            height: 2rem;
            // position: relative;
            display: grid;
            place-items: stretch;
            transition: background-color $trans-effect $trans-regular;
            .wip_progress
            {
                // position: absolute;
                // inset: 0rem;
                margin: 0rem;
                border-radius: $radius-small;
                height: 100%;
            }
        }
    }
    &.wip_project .project_languages
    {
        display: none;
    }
    
    &.featured_project
    {

        grid-column: span 6;
        
        @include media(">tablet-big")
        {
            .project_description
            {
                width: 80%;
            }
        }
        @include media(">tablet", "<=tablet-big")
        {
            .project_description
            {
                width: 100%;
            }
        }
    }
    
    &.large_project
    {
        grid-column: span 6;
        @include media(">tablet")
        {
            grid-column: span 3;
        }
        @include media(">tablet-big")
        {
            .project_description
            {
                width: 100%;
            }
        } 
    } 
    &.error
    {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem;
        grid-column: span 6; 
    }
}

.loading_card
{
    grid-column: span 2;
    height: $project-card-height;
    &.featured_project
    {
        grid-column: span 6;
    }
    
    &.large_project
    {
        grid-column: span 3;
    }
    @include media("<=tablet-big")
    {
        grid-column: span 3;
    }
    @include media("<tablet")
    {
        grid-column: span 6;
        &.large_project
        {
            grid-column: span 6;
        }
    }
}