#pagenotfound
{
    text-align: center;
    margin-top: 25vh;
    .err_title
    {
        transition: color $trans-effect $trans-regular;
        margin: 1rem;
    }
    
    .err_subtitle
    {
        transition: color $trans-effect $trans-regular;
        margin: 1rem;
    }
    
    .err_home
    {
        transition: color $trans-effect $trans-regular;
        margin: 1rem;
        text-decoration: none;
    }
    
    
    @include themify()
    {
        .err_title
        {
            color: themed('primary');
        }
        .err_subtitle
        {
            color: themed('grey-800');
        }
        .err_home
        {
            color: themed('grey-800');
            &:hover
            {
                color: themed('primary');
            }
            &:active
            {
                transition: none;
                color: themed('secondary');
            }
        }
        
    }
}