/*
 * File: _contact.scss
 * Author: Yusuf Saquib
 */

#contact
{

    // @include media("<=tablet")
    // {
    //     grid-column: span 2;
    // }

    #form_error
    {
        padding: 1rem;
        border-radius: $radius-regular;
        margin-bottom: 1rem;
        @include themify()
        {
            color: themed('grey-000');
            background-color: themed('error');
        }
    }

    .message_sent
    {
        margin-top: 0.4rem;
        white-space: pre-line;
        @include themify()
        {
            color: themed('primary');
        }
    }
    .contact_wrapper
    {
        margin-top: 0.4rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5rem;
        align-items: start;
        justify-content: start;
        
        .google_recaptcha_branding
        {
            line-height: 1.5;
            grid-column: span 2;
            text-align: right;
            transition: color $trans-regular $trans-effect;
            a 
            {
                transition: color $trans-regular $trans-effect;
            }
            
            @include media("<=tablet")
            {
                text-align: center;
            }
            @include themify()
            {
                color: themed('grey-750');
                a 
                {
                    text-decoration: none;
                    color: themed('secondary');
                }
            }
        }

        @include media("<=tablet")
        {
            .half
            {
                grid-column: span 2;
            }
        }
        
        .confirmbtn
        {
            grid-column: 2 / 3;
            margin: 0rem;
            justify-self: end;
            align-self: start;
            @include media("<=tablet")
            {
                justify-self: stretch;
                grid-column: span 2;
            }
        }
        
    }
}