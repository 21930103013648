/*
 * File: _projects.scss
 * Author: Yusuf Saquib
 */

.career_section
{   
    .career_button
    {
        margin-top: 4rem;    
        display: flex;
        justify-content: center;
        align-items: center;
    }
}