.overlay
{
    z-index: 11;
    position: fixed;
    top: 0rem;
    left: 0rem;
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
    &::before
    {
        content: '';
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 0rem;
        left: 0rem;
        background-color: black;
        opacity: 0.75;
        
    }
}

.dialog_box
{
    border-radius: $radius-regular;
    box-shadow: $dialog-box-shadow;
    z-index: 12;
    min-height: 40%;
    min-width: 35%;
    margin: 1rem;
    height: fit-content;
    width: fit-content;
    padding: 3rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;
    
    .dialog_box_title
    {
        margin: 0rem;
        text-align: center;
    }

    .dialog_box_messages
    {
        flex: 1 1 auto;
        margin-bottom: 2.5rem;
    }

    .dialog_box_message
    {
        padding: 0.5rem;
        border-radius: $radius-regular;
        margin: 0rem;
        text-align: left;
    }
    
    .dialog_box_buttons
    {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 1rem;
        width: 100%;

        @include media("<=tablet")
        {
            flex-direction: column;
            align-items: stretch;
        }

    }
    
    @include themify()
    {
        background-color: themed('grey-150');
        .dialog_box_title
        {
            color: themed('grey-900');
        }
        .dialog_box_message
        {
            color: themed('grey-800');
            &.error
            {
                background-color: themed('error');
                color: themed('grey-000');
            }
            &.warning
            {
                background-color: themed('warning');
                color: themed('grey-000');
            }
            &.success
            {
                background-color: themed('success');
                color: themed('grey-000');
            }
        }
    }
}