/*
 * File: _about.scss
 * Author: Yusuf Saquib
 */

#about
{
    // @include media("<=tablet")
    // {
    //     grid-column: span 2;
    // }

    .about_wrapper
    {
        width: 100%;
        display: inline-flex;
        flex-direction: column;
        .about_description
        {
            width: 100%;
            margin: 0rem;
            white-space: pre-line;
            flex: 1 1 auto;
        }

        .about_links
        {
            padding: 0rem;
            list-style-type: none;
            width: 100%;
            display: inline-flex;
            justify-content: space-evenly;
            border-radius: $radius-regular;
            box-shadow: $blog-list-box-shadow;
            .about_links_item
            {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                
                a 
                {
                    border: 2px dashed transparent;
                    margin: 1rem 0rem;
                    padding: 0.5rem;
                }
            }
        }
        @include themify()
        {
            color: themed('grey-700');

            .about_links
            {
                background-color: themed("grey-150");
            }
            
            .about_links_item a
            {
                * { transition: none; }

                color: themed("grey-600");
                &:hover
                {
                    color: themed("primary");
                }
                &:focus-visible
                {
                    box-shadow: none;
                    outline: none;
                    color: themed("secondary");
                    border: 2px dashed themed("secondary");
                }
                &:active, &:active:focus
                {
                    color: themed("secondary");
                }
            }
        }
    }
}