#footer
{
    width: 100%;
    margin: auto auto 0rem;
    padding-bottom: 5rem;
    height: 5rem;
    flex: 0 0 auto;
    
    .footer_wrapper
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        
        &::before
        {
            content: "";
            order: 1;
            transition: background-color $trans-effect $trans-regular;
            @include themify()
            {
                background-color: themed('grey-300');
            }
            height: 1px;
            width: $section-width-regular;
            @include media(">tablet-big", "<desktop")
            {
                width: $section-width-more;
            }
            @include media("<=tablet-big")
            {
                width: $section-width-most;
            }

        }
        
        .footer_label
        {
            margin-top: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            order: 2;
            gap: 0.3rem;
            cursor: pointer;
            text-decoration: none;
            transition: color $trans-regular $trans-effect;
            
            * { transition: none; }
            @include themify()
            {
                color: themed('grey-600');
                &:hover
                {
                    color: themed('primary');
                }
            }
        }
    }
}