#pseudo_header
{
    height: $gigantic-size;
    flex: 0 0 auto;
    margin-bottom: $large-size;
    transition: all $trans-effect $trans-fast;
}

#header
{
    height: $gigantic-size;
    width: 100%;
    z-index: 10;
    position: fixed;
    top: 0rem;
    transition: all $trans-effect $trans-regular;
    
    
    
    .header_wrapper
    {
        transition: all $trans-effect $trans-regular;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 2rem;
    }

    .icon_wrapper
    {
        display: flex;
        gap: 1.5rem;
        align-items: center;
        justify-content: center;
    }

    &.scrolling
    {
        height: $huge-size;
        .header_wrapper
        {
            @include media("<=tablet")
            {
                padding: 1rem 2.8rem;
            }
        }
    }

    .header_title
    {
        text-decoration: none;
        border-radius: $radius-regular;
        margin: 0rem;
        padding: 1rem;
        user-select: none;
        &:hover
        {cursor: pointer;}
        a
        {text-decoration: none;}
        @include media("<=tablet")
        {
            padding: 0rem;
        }
    }
    
    .header_list 
    {
        margin: 0rem;
        padding: 0rem;
        overflow: hidden;
        display: inline-flex;
        flex-direction: row;
        gap: 2rem;
        @include media(">desktop-big")
        {
            gap: 4rem;   
        }
        @include media("<=tablet-big")
        {
            gap: 1rem;
        }
        
        @include media("<=tablet")
        {
            padding: 0rem;
            display: flex;
            flex-direction: column;
            text-align: center;
            gap: 0rem;

            .header_item
            {
                padding: 1.5rem;
            }
            justify-content: flex-start;
        }
    }
    
    .header_item
    {
        margin: 3px;
        text-decoration: none;
        border-radius: $radius-regular;
        list-style-type: none;
        user-select: none;
        cursor: pointer;
        display: inline;
        padding: 1rem 1rem;
        counter-increment: inst;
    }

    .header_menu_wrapper
    {
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: flex-end;
        position: fixed;
        inset: 0rem;
        transform: translateX(100vw);
        transition: transform $trans-effect-drawer $trans-slowest;
        overflow: hidden;
        &.open
        {
            transform: translateX(0vw);
        }
    }
    
    .header_menu
    {
        z-index: 11;
        width: 75vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        box-shadow: $menu-box-shadow;
        transition: background-color $trans-effect $trans-regular;
    }

    .menu_icon
    {
        transition: color $trans-effect $trans-regular;
        padding: 0.5rem;
    }


    @include themify 
    {
        
        .menu_icon
        {
            color: themed("grey-750");
        }

        .header_wrapper .header_title,
        .header_list .header_item
        {
            transition: color $trans-effect $trans-regular, border-color $trans-effect $trans-regular;
            border: 1px solid transparent;
            color: themed("grey-750");
            
            &:hover
            {
                color: themed("primary");
            }
            &:focus-visible
            {
                outline: themed("secondary") dashed 2px;
            }
            &:active:focus, &:active
            {
                transition: none;
                color: themed("secondary");
            }
            
            &.selected_path
            {
                color: themed("secondary");
            }
        }
        
        .header_list .header_item
        {
            &:hover
            {
                border-color: currentColor;
            }
            &.selected_path
            {
                border-color: currentColor;
            }
            &:active:focus, &:active
            {
                border-color: currentColor;
            }   
        }

        
        .sign_out
        {
            &:hover
            {
                color: themed("error") !important;
            }
            &:active
            {
                color: mix(themed("error"), themed("warning"), 50%) !important;
            }
        }

        
        background-color: transparent;
        box-shadow: none;
        &.scrolling
        {
            box-shadow: $header-box-shadow;
            background-color: themed("grey-200");
            
        }
        .header_menu
        {
            background-color: themed("grey-200");
        }
    }
}