/*
 * File: _base.scss
 * Author: Yusuf Saquib
 */

#root
{
    min-height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}


*, *:before, *:after
{
    box-sizing: border-box;
}


.firebase-emulator-warning { display: none; }