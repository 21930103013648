.toolbar
{

    width: 100%;
    padding: 0.5rem;
    border-radius: $radius-regular;
    margin-bottom: 1rem;

    // box-shadow: 0.1rem 0.1rem 0.5rem 0.0rem rgba(#000, 20%);

    display: flex;
    flex-wrap: wrap;

    .toolbar_button
    {
        margin: 0.2rem;
        padding: 0.3rem;
        border: 0.1rem solid;
        border-radius: $radius-regular;
        width: fit-content;
        transition: border-color $trans-effect $trans-regular, background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;
        * {
            transition: none;
        }
        
        &:hover
        {
            cursor: pointer;
        }
    }

    transition: background-color $trans-effect $trans-regular;

    .toolbar_divider
    {
        padding: 0.04rem;
        margin: 0.1rem 0.5rem;
        transition: background-color $trans-effect $trans-regular;
    }


    @include themify()
    {

        background-color: themed('grey-200');
        
        .toolbar_button
        {
            border-color: themed('grey-200');
            color: themed('grey-800');
            &:hover
            {
                border-color: themed('grey-300');
                background-color: themed('grey-300');
            }
            &:active
            {
                transition: none;
                border-color: themed('secondary');
                color: themed('secondary');
                background-color: themed('grey-150');
            }
        }
        
        .toolbar_divider
        {
            background-color: themed('grey-600');
        }
    }
}

.editor_wrapper
{
    .editor_area 
    {
        padding: 2rem;
        resize: vertical;
        &:hover
        {
            @include themify() {background-color: themed('grey-150');}
        }
        
    }
}