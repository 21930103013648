// MEDIA QUERY MANAGER
/*
 * 0 - 600px:          Phone
 * 600px - 900px       Tablet Portrait
 * 900px - 1200px      Tablet Landscape
 * [1200px - 1800px]   Desktop Normal Styles -- Default
 * 1800px +            Big Desktop
 * 
 * 1em = 16px
 * 
 * ORDER: Base + Typography > General Layout + Grid > Page Layout > Components
 * 
 */
@mixin respond($breakpoint) 
{
  /* Small Phone --          0-320px */
  @if $breakpoint == mobile-small 
  {
    @media (min-width: 0px) and (max-width: 320px) 
    {
      @content;
    }
  }
  /* Phone --                0-600px */
  @if $breakpoint == mobile
  {
    @media (min-width: 321px) and (max-width: 650px) 
    {
      @content;
    }
  }
  /* Tablet Portrait --      900px */
  @if $breakpoint == tablet-portrait
  {
    @media (min-width: 651px) and (max-width: 900px) 
    {
      @content;
    }
  }
  /* Tablet Landscape --     1200px */
  @if $breakpoint == tablet-landscape 
  {
    @media (min-width: 901px) and (max-width: 1200px) 
    {
      @content;
    }
  }
  /* Desktop Normal          1200 - 1800px */
  @if $breakpoint == desktop 
  {
    @media (min-width: 1201px) and (max-width: 1799px) 
    {
      @content;
    }
  }
  /* Big Desktop --          1800+ px*/
  @if $breakpoint == desktop-big 
  {
    @media (min-width: 1800px) 
    {
      @content;
    }
  }
}

// Code from https://medium.com/@dmitriy.borodiy/easy-color-theming-with-scss-bc38fd5734d1

/* 
 * This function will add the parent class to each theme class with
 * children as specified. 
 */
@mixin themify($themes: $themes) 
{
  @each $theme, $map in $themes 
  {
    .theme-#{$theme} & 
    {
      $theme-map: () !global;
      @each $key, $submap in $map 
      {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}