/*
 * File: _section.scss
 * Author: Yusuf Saquib
 */
section 
{
    margin: 0rem auto;
    width: $section-width-regular;
    text-align: left;
    // flex: 1;
    
    @include media(">tablet-big", "<=desktop")
    {
        width: $section-width-more;
    }
    @include media("<=tablet-big")
    {
        width: $section-width-most;
    }
    
    &.mini
    {
        padding: 0rem;
        margin: 0rem;
        width: 100%;
    }

    .sec_title
    {
        transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        
        &::after, &::before
        {
            content: "";
            flex: 1;
            height: 1px;
            width: 100%;
            transition: background-color $trans-effect $trans-regular;
        }
        &::before
        {
            margin-right: 3rem;
            @include media("<=tablet")
            {
                margin-right: 2rem;
            }
            @include media("<=phone")
            {
                margin-right: 1.5rem;
            }
        }
        &::after
        {
            margin-left: 3rem;
            @include media("<=tablet")
            {
                margin-left: 2rem;
            }
            @include media("<=phone")
            {
                margin-left: 1.5rem;
            }
        }
    }
    
    .sec_subtitle
    {
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        &::after
        {
            content: "";
            flex: 1;
            height: 1px;
            width: 100%;
        }
        &::after{margin-left: 3rem;}
        
    }

    @include themify()
    {
        .sec_subtitle, .sec_title
        {
            color: themed("grey-900");
            &::after, &::before
            {
                background-color: themed('grey-300');
            }
        }
    }
}

#main_container
{
    padding-left: 24px;
    padding-right: 24px;
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 5vh;
    flex: 1;

    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
}