#login, #register
{
    margin: auto;
    width: 33%;
    
    @include media(">tablet", "<=tablet-big")
    {
        width: 66%;
    }
    @include media("<=tablet")
    {
        width: 100%;
    }
    
    // @include themify()
    // {
    //     background-color: themed('grey-000');
    // }
    
    .sec_title
    {
        margin-top: 0rem;
    }
    
    .login_wrapper, .register_wrapper
    {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5rem;

        @include media("<=tablet")
        {
            .input_wrapper.half
            {
                grid-column: span 2;
            }
        }
        
        .banner
        {
            border-radius: $radius-regular;
            margin: 0rem;
            grid-column: span 2;
            padding: 1rem;
            @include themify()
            {
                color: themed('grey-000');
                background-color: themed('error');
                &.success
                {
                    background-color: themed('success');
                }
            }
        }

        .confirmbtn
        {
            margin: 0rem;
            margin-top: 2rem;
            justify-self: end;
            align-self: center;
        }
        .leftbtn
        {
            :nth-child(n)
            {
                padding-left: 0rem;
                padding-right: 0rem;
                text-align: left;
            }
            text-decoration: none;
            margin: 0rem;
            margin-top: 2rem;
            justify-self: start;
            align-self: center;
            padding: 0rem;
            @include themify()
            {
                color: themed('grey-600');
            }
        }
    }

    
}