/*
 * File: _projects.scss
 * Author: Yusuf Saquib
 */

.projects
{
    .projects_wrapper
    {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 1.5rem;
    }
    
    .projects_button
    {
        margin-top: 4rem;    
        display: flex;
        justify-content: center;
        align-items: center;
    }
}