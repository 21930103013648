#blog_loader
{
    .title_loader
    {
        height: 10rem;
        width: 100%;
        margin: 4rem 0 3rem 0;
        @include themify()
        {
            color: mix(themed("primary"), themed("grey-000"), 25%);
            &::before
            {
                color: mix(themed("primary"), themed("grey-000"), 35%);
            }
        }
    }

    .content_loader
    {
        height: 70vh;
        width: 100%;
    }

}

#blog
{
    margin-top: 2.5rem;


    #blog_edit_button
    {
        transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        margin-bottom: 1rem;
        width: 100%;
        
        border-radius: $radius-large;
    }

    #blog_button_back
    {
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 2rem;
        transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;
    }

    #blog_header
    {   
        margin-bottom: 4rem;
        
        #blog_title
        {
            transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;
            margin: auto;
            text-align: center;
            
            border-radius: $radius-regular;
            padding: 2.5rem;
            @include media("<=tablet")
            {
                text-align: left;
                padding: 1.8rem;
            }
        }
        
        #blog_info
        {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: row;
            margin: 1rem 0rem;
            gap: 2rem;
            
            @include media("<=tablet")
            {
                text-align: left;
                // flex-direction: column-reverse;
                align-items: flex-start;
                justify-content: space-between;
            }
            
            .info_item
            {
                transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;
                margin: 0rem;
                flex: 1 0 auto;
            }
        }

        #blog_tag_list
        {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            align-items: center;
            gap: 1rem;
            padding: 0rem;
            margin: 0rem;
        }
        
        .blog_tag
        {
            transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;
            list-style-type: none;
            padding: 0.2rem 0.6rem;
            border-radius: $radius-small;
        }
    }
    
    @include themify()
    {
        #blog_edit_button
        {
            color: themed("grey-000");
            background-color: mix(themed("grey-000"), themed("primary"), 50%);
            &:hover
            {
                background-color: mix( themed("primary"),themed("grey-100"), $color-mix-least);
                cursor: pointer;
            }
            
            &:active
            {
                transition: none;
                background-color: mix( themed("primary"),themed("grey-150"), $color-mix-less);
            }
        }

        #blog_button_back
        {
            color: themed("grey-700");
            &:hover
            {
                cursor: pointer;
                color: themed("primary");
            }
            &:active
            {
                color: themed("secondary");
                transition: none;
            }
        }

        #blog_header
        {
            .blog_tag
            {
                background-color: themed("primary");
                color: themed("grey-000");
            }
            #blog_title
            {
                background-color: themed("primary");
                color: themed("grey-000");
            }
            #blog_info .info_item
            {
                color: themed("primary");
            }
        }
        &.featured
        {
            #blog_header
            {
                .blog_tag
                {
                    background-color: themed("secondary");
                }
                #blog_title
                {
                    background-color: themed("secondary");
                }
                #blog_info .info_item
                {
                    color: themed("secondary");
                }
            }
            #blog_edit_button
            {
                background-color: mix(themed("grey-000"), themed("secondary"), 50%);
                &:hover
                {
                    background-color: mix( themed("secondary"),themed("grey-100"), $color-mix-least);
                }
                
                &:active
                {
                    background-color: mix( themed("secondary"),themed("grey-150"), $color-mix-less);
                }
            }
        }
        &.wip
        {
            #blog_header
            {
                .blog_tag
                {
                    background-color: themed("grey-700");
                }
                #blog_title
                {
                    background-color: themed("grey-700");
                }
                #blog_info .info_item
                {
                    color: themed("grey-700");
                }
            }
            #blog_edit_button
            {
                background-color: mix(themed("grey-000"), themed("grey-700"), 50%);
                &:hover
                {
                    background-color: mix( themed("grey-700"),themed("grey-100"), $color-mix-least);
                }
                
                &:active
                {
                    background-color: mix( themed("grey-700"),themed("grey-150"), $color-mix-less);
                }
            }
        }
        
        
    }
}

#edit_blog
{
    @include themify() 
    {
        color: themed("grey-800");
    }

    #editor_buttons
    {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 2rem;
        @include media("<=tablet")
        {
            flex-direction: column;
            align-items: stretch;
            gap: 1rem;
            justify-content: flex-start;
        }
        
        .button_group
        {
            display: inline-flex;
            gap: 1.5rem;
            @include media("<=tablet")
            {
                gap: 1rem;
                flex-direction: column;
            }
        }
    }

    .blog_input_group
    {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        align-items: center;
        @include media("<=tablet")
        {
            gap: 0rem;
            flex-direction: column;
            align-items: flex-start;
            .half
            {
                margin-bottom: 2rem;
            }
        }
    }
    
    .blog_field
    {
        margin: 0rem auto 2rem;
    }

    .blog_title_field
    {
        input 
        {
            padding: 2rem;            
        }
        margin-bottom: 2rem;
    }

    .editor_wrapper
    {
        margin-top: 5rem;
    }
}