#admin
{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    gap: 2rem;
    padding: 0rem;
}

.admin_title
{
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    margin-bottom: 5rem;

    &::after, &::before
    {
        content: "";
        flex: 1;
        @include themify()
        {
            background-color: themed("grey-300");
            transition: background-color $trans-effect $trans-regular;
        }
        height: 1px;
        width: 100%;
    }
    &::before{margin-right: 3rem;}
    &::after{margin-left: 3rem;}
}


.admin
{
    width: 100%;
    height: 100%;
    margin: 0rem;

    &.options
    {
        grid-column: span 2;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
    }

    .edit
    {
        transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin: 0rem;
        box-shadow: $admin-edit-box-shadow;

        gap: 1.5rem;
        padding: $padding-card-regular;
        @include media("<=tablet")
        {
            padding: $padding-card-least;
        }
        border-radius: $radius-regular;
        
        &.banner
        {
            flex: 1 1 auto;
            height: auto;  
            @include media("<=tablet")
            {
                .input_wrapper.half
                {
                    grid-column: span 2;
                }          
            }
        }
        
        &.about
        {
            height: 100%;
            .about_checkboxes
            {
                justify-content: space-between;
                width: 100%;
                grid-column: span 2;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
            }
            .about_desc
            {
                height: 100%;
                textarea
                {
                    flex: 1 1 auto;
                }
                margin: 0rem;
            }
        }
        &.options
        {
            grid-column: span 2;
            @include media("<=tablet")
            {
                grid-column: span 3;
                .input_wrapper.half
                {
                    grid-column: span 2;
                }
            }
            height: fit-content;
            align-content: start;
            .desc_textarea
            {
                grid-row: span 2;
            }
            .checkboxes
            {
                align-self: end;
                width: 100%;
                display: inline-flex;
                flex-direction: column;
                gap: 1rem;
                height: fit-content;
            }
        }

        .category_title
        {
            transition: color $trans-effect $trans-regular;
            margin-top: 0rem;
            margin-bottom: 2rem;
            grid-column: span 2;
            justify-self: start;
            
        }
        .confirmbtn
        {
            margin: 2rem 0rem 0rem 0rem;
            justify-self: end;
            align-self: center;
            @include media("<=tablet")
            {
                width: 100%;
                grid-column: span 2;
            }
        }
        
        .message
        {
            @include media("<=tablet")
            {
                grid-column: span 2;
            }
            justify-self: start;
            align-self: center;
            margin: 2rem 0rem 0rem 0rem;
        }

        @include themify()
        {
            &.new
            {
                input, textarea
                {
                    &:focus, &:active
                    {
                        border-color: themed("secondary");
                    }
                }
                input[type=checkbox]
                {
                    &:checked, &:checked:active
                    {
                        color: themed('secondary');
                        background-color: themed('secondary');
                    }
                }
                .confirmbtn
                {
                    background-color: themed("secondary");
                }
            }

            .category_title 
            {
                color: themed("grey-900");
            }
            .message
            {
                color: themed("success");
                &.error 
                {
                    color: themed("error");
                }
                
            }
            // border: 0.1rem solid themed("grey-500");
            background-color: themed("grey-150");
        }
    }
}

#admin_about
{
    @include media("<=tablet")
    {
        grid-column: span 2;
    }
}

#admin_banner
{

    display: inline-flex;
    flex-direction: column;
    gap: 2rem;

    @include media("<=tablet")
    {
        grid-column: span 2;
    }

    #banner
    {
        @include themify()
        {
            border: 0.1rem solid themed("grey-900");
            transition: border-color $trans-effect $trans-regular;
        }

        border-radius: $radius-regular;
        width: 100%;
        height: auto;
        margin: 0rem;
        padding: 3rem;
        .banner_wrapper
        {
            padding: 0rem; // 4rem 3rem 4rem;
            .banner_buttons
            {
                gap: 1rem;
                .btn
                {
                    padding: 0.7rem 0.9rem;
                    min-width: 8rem;
                    min-height: 2.8rem;
                }
            }    
            
            .my_title { margin-bottom: 2rem; } 
        }
    }
}

.admin_list
{
    .item_list
    {
        padding: 0rem;
        margin: 0rem;
        li
        {
            margin-bottom: 1rem;
        }
    }

    @include media("<=tablet")
    {
        grid-column: span 3;
    }

    
    .item
    {
        box-shadow: $admin-proj-list;
        border-radius: $radius-regular;
        list-style-type: none;
        padding: 0.8rem 1rem;
        display: inline-flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        
        &:hover
        {
            cursor: pointer;
        }
        
        .item_title
        {
            margin: 0rem;
        }
        .chevron
        {
            width: $medium-size;
            height: $medium-size;
        }
    }

    .new_item
    {
        grid-column: span 2;
    }

    .item_buttons
    {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;


        .button_add, .button_delete
        {
            width: 100%;
            display: grid;
            place-content: center;
        }
    }
    
    @include themify()
    {
        .item
        {
            transition: color $trans-regular $trans-effect, background-color $trans-regular $trans-effect;
            
            background-color: themed("grey-150");
            color: themed("grey-900");
            &:hover
            {
                background-color: themed("grey-200");
                color: themed("primary");
            }
            &.selected
            {
                background-color: themed("primary");
                color: themed("grey-000");
                box-shadow: $admin-proj-list-sel;
            }
            
            &.wip
            {
                background-color: mix(themed("grey-000"), themed("warning"), $color-mix-least);
                color: mix(themed("warning"), themed("grey-700"), $color-mix-least);
                box-shadow: $admin-proj-list-sel;
                &:hover
                {
                    background-color: mix(themed("grey-150"), themed("warning"), $color-mix-least);
                    color: mix(themed("warning"), themed("grey-900"), $color-mix-least);
                }
                &.selected
                {
                    color: mix(themed("grey-000"), themed("warning"), $color-mix-least);
                    background-color: mix(themed("warning"), themed("grey-900"), $color-mix-regular);
                }
            }

            &.hidden
            {
                background-color: mix(themed("grey-000"), themed("error"), $color-mix-least);
                color: mix(themed("error"), themed("grey-700"), $color-mix-least);
                box-shadow: $admin-proj-list-sel;
                &:hover
                {
                    background-color: mix(themed("grey-150"), themed("error"), $color-mix-least);
                    color: mix(themed("error"), themed("grey-900"), $color-mix-least);
                }
                &.selected
                {
                    color: mix(themed("grey-000"), themed("error"), $color-mix-least);
                    background-color: mix(themed("error"), themed("grey-900"), $color-mix-regular);
                }
            }
            
            &.new_items
            {
                background-color: themed("secondary");
                color: themed("grey-000");
                box-shadow: $admin-proj-list-sel;
                cursor: default;
            }
        }


        .item_buttons
        {
            .button_add
            {
                background-color: themed("grey-150");
                color: themed("grey-500");
                &:hover 
                {
                    color: themed("grey-000");
                    background-color: mix(themed("success"), themed("grey-150"), $color-mix-least);
                }
            }
            .button_delete
            {
                color: themed("grey-500");
                background-color: themed("grey-150");
                &:hover 
                {
                    color: themed("grey-000");
                    background-color: mix(themed("error"), themed("grey-150"), $color-mix-least);
                }
            }
        }
    }
}