/* The following code is for the loading cards only */

@keyframes shine {
    0% {
        transform: translate(-100%);
    } 60% {
        transform: translate(100%);
    } 100% {
        transform: translate(100%);
    }
}

.loading_skeleton
{
    border-radius: $radius-large;
    position: relative;
    overflow: hidden;
    transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;
    &::before
    {
        &.circle
        {
            border-radius: 50rem;
        }
        position: absolute;
        width: 100%;
        height: 100%;
        content: "";
        
        top: 0rem;
        left: 0rem;
        right: 0rem;
        bottom: 0rem;
        
    }
    @include themify()
    {
        color: themed("grey-150");
        background-color: currentColor;
        &::before
        {
            color: mix(themed("grey-200"), themed("grey-300"), 80%);
            background: linear-gradient(90deg, transparent, currentColor, transparent);
            transform: translateX(-100%);
            animation: $anim-long shine linear infinite;
        }
    }
}