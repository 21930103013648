#inbox
{

    display: grid;
    grid-template-columns: repeat(5, 1fr);
    height: 70vh;
    @include media("<=tablet")
    {
        height: fit-content;
    }
    
    // width: 100%;
    padding: 0rem;
    margin-bottom: 0rem;
    gap: 1.5rem;
    
    .loading_list
    {
        overflow: hidden !important;
    }
    
    #message_list
    {
        border-radius: $radius-large;
        grid-column: span 2;
        padding: 1.2rem 1rem;
        margin: 0rem;
        overflow-y: auto;
        list-style-type: none;
        box-shadow: $inbox-shadow;
        
        @include media("<=tablet")
        {
            grid-column: span 5;
            height: 50vh;
        }

        :not(:last-child)
        {
            margin-bottom: 1rem;
        }
        
        .message_loader
        {
            box-shadow: none !important;
            width: 100%;
            height: 5rem;       
        }
        
        transition: background-color $trans-effect $trans-regular;
        .message_li
        {
            transition: background-color $trans-effect $trans-regular;
            padding: 1.5rem;
            border-radius: $radius-regular;
            display: grid;
            grid-template-columns: 1.4fr 0.6fr;
            grid-template-rows: repeat(2, fit-content);
            gap: 0.5rem;
            
            .msgli
            {
                margin: 0rem;
                transition: color $trans-effect $trans-regular;
                &.sender
                {
                    place-self: start start;
                    grid-column: span 1;
                    grid-row: span 1;
                }
                &.time
                {
                    place-self: start end;
                    text-align: right;
                    grid-column: span 1;
                    grid-row: span 1;
                }
                &.subject
                {
                    place-self: end start;
                    grid-column: span 2;
                    grid-row: span 1;
                }
            }

            &:hover
            {
                cursor: pointer;
            }
        }
    }
    
    #message
    {
        transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;
        border-radius: $radius-regular;
        box-shadow: $inbox-shadow;

        display: inline-flex;
        flex-direction: column;
        grid-column: span 3;
        padding: 2rem;

        @include media("<=tablet")
        {
            grid-column: span 5;
        }
        
        .msginfo
        {
            margin: 0rem 0rem;
            width: 100%;
            padding: 0rem 0rem;

            &.subject
            {
                margin-bottom: 1rem;
            }
            
            &.sender
            {
                margin-bottom: 0.3rem;
            }
            &.date
            {
                margin-bottom: 3rem;
            }
            &.content
            {
                line-height: 1.5;
            }

            &.buttons
            {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                margin: 0rem;
                padding: 0rem;
                gap: 1.5rem;
                transition: background-color $trans-effect $trans-regular;
                .msgbtn
                {
                    transition: background-color $trans-effect $trans-regular;
                    border-radius: $radius-regular;
                    padding: 0.75rem;
                    display: grid;
                    place-content: center;
                    &:hover
                    {
                        cursor: pointer;
                    }
                }
            }
            &.content
            {
                overflow-y: auto;
                flex: 1 1 auto;
                @include media("<=tablet")
                {
                    min-height: 50vh;
                }
            }
        }
    }


    .empty_list, .empty_message
    {
        display: grid !important;
        place-content: center;
    }

    @include themify()
    {

        .empty_list
        {
            color: themed("grey-700");
        }
        #message_list
        {
            background-color: themed("grey-150");
            
            .message_loader 
            {
                background-color: themed("grey-200");
                &::before
                {
                    color: mix(themed("grey-300"),themed("grey-200"),50%);
                }
            }

            .message_li
            {
                background-color: themed("grey-200");
                .msgli
                { 
                    color: themed("grey-800");
                }
                &:hover
                {
                    background-color: themed("grey-300");
                }
                &:active
                {
                    transition: none;
                    background-color: themed("grey-400");
                }
            }
            
            .message_li.new_msg
            {
                background-color: mix(themed("secondary"), themed("grey-000"), 35%);
                .msgli
                {
                    color: mix(themed("secondary"), themed("grey-900"), 20%);
                }
                &:hover
                {
                    background-color: mix(themed("secondary"), themed("grey-000"), 60%);
                }
                &:active
                {
                    transition: none;
                    background-color: mix(themed("secondary"), themed("grey-000"), 80%);
                }
            }

            .message_li.selected_msg
            {
                background-color: themed("primary");
                .msgli
                {
                    color: mix(themed("primary"), themed("grey-000"), 10%);
                }
                &:hover
                {
                    background-color: mix(themed("primary"), themed("grey-900"), 70%);
                }
                &:active
                {
                    transition: none;
                    background-color: mix(themed("primary"), themed("grey-900"), 50%);
                }
            }
        }

        #message
        {
            background-color: themed("grey-200");
            &.empty_message
            {
                background-color: themed("grey-150");
            }
            color: themed("grey-900");
            .msginfo
            {
                // background-color: themed("grey-200");
                &.buttons
                {
                    // background-color: themed("grey-150");
                    .delete
                    {
                        background-color: mix(themed("error"), themed("grey-000"), 50%);
                        &:hover
                        {
                            background-color: mix(themed("error"), themed("grey-000"), 70%);
                        }
                        &:active
                        {
                            transition: none;
                            background-color: mix(themed("error"), themed("grey-000"), 80%);
                        }
                    }

                    .save
                    {
                        background-color: mix(themed("info"), themed("grey-000"), 50%);
                        &:hover
                        {
                            background-color: mix(themed("info"), themed("grey-000"), 70%);
                        }
                        &:active
                        {
                            transition: none;
                            background-color: mix(themed("info"), themed("grey-000"), 80%);
                        }
                    }

                    .reply
                    {
                        background-color: mix(themed("success"), themed("grey-000"), 50%);
                        &:hover
                        {
                            background-color: mix(themed("success"), themed("grey-000"), 70%);
                        }
                        &:active
                        {
                            transition: none;
                            background-color: mix(themed("success"), themed("grey-000"), 80%);
                        }
                    }

                }
            }
        }
    }
}