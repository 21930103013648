/*
 * File: _banner.scss
 * Author: Yusuf Saquib
 */

#banner
{
    margin: 20vh auto 45vh;
    .banner_wrapper
    {
        margin: 0rem;
        padding: 0rem;
        @include media(">tablet-big")
        {
            padding: $padding-card-regular;
        }
        @include media(">tablet", "<=tablet-big")
        {
            padding: $padding-card-less;
        }
        @include themify()
        {
            background-color: themed('background-dark');
        }

        display: flex;
        flex-direction: column;

        .banner_buttons
        {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            align-items: stretch;
            // @include media("<tablet")
            // {
            //     flex-direction: column;
            //     align-items: stretch;
            // }
            gap: 2rem;
            
        }    

        .pre
        {
            text-align: left;
            margin: 0rem;
            transition: color $trans-effect $trans-regular;
        }

        .my_name
        {
            margin: 0rem; //0rem 4rem 0rem;
            text-align: left;
            transition: color $trans-effect $trans-regular;

            @include themify()
            {
                color: themed('grey-900');
            }
        }

        .my_title
        {
            transition: color $trans-effect $trans-regular;
            margin: 0rem 0rem 4rem 0rem;
            text-align: left;
            @include themify()
            {
                color: themed('grey-700');
            }
        }

    }
}