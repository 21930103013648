/*
 * File: _variables.scss
 * Author: Yusuf Saquib
 *
 * This file holds all the variables that are used throughout the project
 */

// # Media Query Breakpoints --------------------------------------------------

$breakpoints: (
    "phone-small": 325px,
    "phone": 480px,
    "tablet": 768px,
    "tablet-big": 1024px,
    "desktop": 1200px,
    "desktop-big": 2200px,
);

// # Text Variables -----------------------------------------------------------

$tiny-size:        1.0rem;
$Tiny-size:        1.1rem;
$little-size:      1.2rem;
$Little-size:      1.3rem;
$small-size:       1.4rem;
$Small-size:       1.5rem;
$regular-size:     1.6rem;
$Regular-size:     1.8rem;
$medium-size:      2.0rem;
$mmedium-size:     2.3rem;
$Medium-size:      2.5rem;
$MMedium-size:     2.8rem;
$large-size:       3.0rem;
$llarge-size:      3.4rem;
$larger-size:      3.7rem;
$Large-size:       4.0rem;
$Largest-size:     4.5rem;
$huge-size:        5.0rem;
$huger-size:       5.5rem;
$Huge-size:        6.0rem;
$gigantic-size:    7.0rem;

// # Line Height Variables ----------------------------------------------------

$line-height-regular:   1;
$line-height-medium:    1.5;
$line-height-large:     1.75;

// # Font Variables -----------------------------------------------------------

$sans-font:    'Fira Sans', 'Noto Naskh Arabic', sans-serif;
$mono-font:    'Fira Code', monospace;
$serif-font:   'Bitter', serif;

// # Font Weight Variables ----------------------------------------------------

$thin-weight:       100;
$lighter-weight:    200;
$light-weight:      300;
$regular-weight:    400;
$medium-weight:     500;
$semi-weight:       600;
$bold-weight:       700;
$bolder-weight:     800;
$black-weight:      900;

// # Effect Variables ---------------------------------------------------------

// $color-mix-least: 82.5%;
$color-mix-least: 70%;
$color-mix-less: 85%;
$color-mix-regular: 90%;
$color-mix-more: 95%;
$color-mix-most: 97.5%;

$trans-effect: ease;
$trans-effect-drawer: ease-in-out;


$trans-fastest: 0.15s;
$trans-fast: 0.2s;
$trans-regular: 0.3s;
$trans-slow: 0.4s;
$trans-slowest: 0.5s;

$anim-long: 2s;

// # Scrollbar Variables ------------------------------------------------------

$doc-scrollbar-track: 'grey-200';
$doc-scrollbar-thumb: 'grey-500';
$doc-scrollbar-thumb-hover: 'grey-600';
$doc-scrollbar-width: 1.2rem;

$scrollbar-track: 'grey-300';
$scrollbar-thumb: 'grey-600';
$scrollbar-thumb-hover: 'primary';
$scrollbar-width: 0.9rem;

// # Border Radius Variables --------------------------------------------------

// $radius-small:      0.2rem;
$radius-small:      1rem;
// $radius-regular:    0.3rem;
$radius-regular:    1.2rem;
// $radius-medium:     0.4rem;
$radius-medium:     1.3rem;
// $radius-large:      0.5rem;
$radius-large:      1.4rem;

// # Box Shadow Variables -----------------------------------------------------

$project-card-box-shadow: 0.1rem 0.1rem 0.3rem 0.1rem rgba(#000, 22%);
$admin-edit-box-shadow:   0.1rem 0.1rem 0.3rem 0.1rem rgba(#000, 22%);
$admin-proj-list:         0.1rem 0.1rem 0.2rem 0.1rem rgba(#000, 22%);
$admin-proj-list-sel:     0.1rem 0.1rem 0.2rem 0.1rem rgba(#000, 35%);
$image-shadow:            0.1rem 0.1rem 1.0rem 0.1rem rgba(#000, 22%);
$blog-list-box-shadow:    0.1rem 0.1rem 0.3rem 0.1rem rgba(#000, 22%);
$inbox-shadow:            0.1rem 0.1rem 0.5rem 0.1rem rgba(#000, 22%);
$messages-list-shadow:    0.1rem 0.1rem 0.5rem 0.0rem rgba(#000, 22%);
$header-box-shadow:       0.0rem 0.1rem 1.0rem 0.5rem rgba(#000, 10%);
$dialog-box-shadow:       0.0rem 0.1rem 3.0rem 0.5rem rgba(#000, 25%);
$menu-box-shadow:        -2.0rem 0.0rem 5.0rem 0.5rem rgba(#000, 75%);

// # Padding and Margin Variables ---------------------------------------------

$padding-card-least:    2rem;
$padding-card-less:     2.5rem;
$padding-card-regular:  3rem;

// # Width and Height Variables -----------------------------------------------

$project-card-height:   30rem;
$career-card-height:    12rem;
$section-width-regular: 75%;
$section-width-more:    90%;
$section-width-most:    100%;