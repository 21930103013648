.markdown_content
{

    width: 100%;
    margin: 3rem 0rem;


    :first-child
    {
        margin-top: 0rem;
    }
    
    font: $regular-weight $regular-size $sans-font, sans-serif;    
    line-height: 1.75;
    
    p, > span
    {
        margin: 1.6rem 0rem;
        transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;
    }
    
    li 
    {
        margin: 1.2rem 0rem;
        transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;
        line-height: 1.00;
    }

    footer 
    {
        margin: 3.2rem 0rem 0rem;
        border-top: 1px solid;
        padding-top: 1.2rem;
        font: $regular-weight $Small-size $sans-font, sans-serif;
    }
    
    p > code
    {
        font: $regular-weight $Small-size $mono-font, monospace;
        transition: background-color $trans-effect $trans-regular;
        border-radius: $radius-regular;
        padding: 0.15rem 0.3rem;
    }
    
    hr 
    {
        border: transparent;
        border-top: 1px solid;
        margin: 2.4rem 0rem;
        transition: border-color $trans-effect $trans-regular;

    }

    pre
    {
        transition: background-color $trans-effect $trans-regular;
        > code 
        {
            :nth-child(n)
            {
                transition: color $trans-effect $trans-regular;
            }
            font: $medium-weight $Small-size $mono-font, monospace;
        }
        border-radius: $radius-regular;
    }

    blockquote
    {
        transition: background-color $trans-effect $trans-regular, border-color $trans-effect $trans-regular;

        p 
        {
            margin: 0rem;
            padding: 1rem 0rem;
        }
        margin: 1.5rem 0rem;
        padding: 0rem 2rem;
    }

    input[type=checkbox]
    {
        width: $regular-size;
        height: $regular-size;
        
        &:checked
        {
            &::after
            {
                content: "\2714";
                transition: color $trans-effect $trans-regular;
                font-size: $regular-size;
            }
        }
        transition: background-color $trans-effect $trans-regular, border-color $trans-effect $trans-regular;

        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        vertical-align: middle;
        margin: 0rem 0rem;
        margin-right: 0.8rem;
    }
    
    .md_image 
    {
        img 
        {
            border-radius: $radius-regular;
            max-width: 100%;
        }
        vertical-align: middle;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 4rem 0rem;
    }


    th, td 
    {
        padding: 1rem;
        transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular, border-color $trans-effect $trans-regular;
    }
    
    table 
    {
        margin: 1.6rem auto;
        min-width: 50%;
        border-collapse: collapse;
    }
    
    a 
    {
        cursor: pointer;
        text-decoration: none;
        &:hover
        {
            text-decoration: underline;
        }
        transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;
    }
    
    h1
    {
        font: $bold-weight $larger-size $serif-font, serif;
    }

    h2
    {
        font: $bolder-weight $llarge-size $sans-font, sans-serif;
    }
    
    h3
    {
        font: $semi-weight $MMedium-size $mono-font, monospace;
    }

    h4
    {
        font: $light-weight $Medium-size $mono-font, monospace;
    }
    
    h5
    {
        font: $medium-weight $mmedium-size $serif-font, serif;
    }
    
    h6
    {
        font: $bold-weight $mmedium-size $sans-font, sans-serif;
    }
    
    h1, h2, h3, h4, h5, h6
    {
        margin: 3.5rem 0rem 2rem;
        transition: background-color $trans-effect $trans-regular, color $trans-effect $trans-regular;
    }
    
    
    @include themify()
    {
        color: themed("grey-750");
        
        hr, footer 
        {
            border-color: themed("grey-700");
        }

        p > code, pre
        {
            background-color: themed("grey-150") !important;
        }

        
        blockquote
        {
            border-left: 0.4rem solid themed("primary");
            background-color: themed("grey-150");
        }
        
        a 
        {
            color: themed("secondary");
            text-decoration: none;
            &:hover
            {
                color: themed("primary");
                text-decoration: underline;
            }
        }
        
        table, td, th
        {
            border: 1px solid themed("grey-400");
            // background-color: themed("grey-200");
        }
        
        th 
        {
            background-color: themed("grey-200");
            border-bottom: 2px solid themed("grey-400");
        }
        

        h1, h2, h3, h4, h5, h6
        {
            color: themed("grey-900");
        }

        
    }
    
}